import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { isEmpty } from 'lodash'

import { routes } from '../../constants/routes'

const PageHeader = ({ auth, fireauth, dispatch }) => {
    const renderMenu = () => {
        if (isEmpty(auth) || !('authId' in auth)) {
            return null
        }
        return (
            <div className="menu">
                <div className="wrapper">
                    <div className="links">
                        <NavLink to={routes.trancheIndex}>Tranche Explorer</NavLink>
                    </div>
                    <div className="links">
                        <NavLink to={routes.authLogout}>Logout</NavLink>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="page-header">
            <div className="container">
                <div className="logo-holder">
                    {/* <Link to={!isEmpty(auth) && 'authId' in auth ? routes.appIndex : routes.index}> */}
                    <a href={process.env.REACT_APP_MARKETING_URL}>
                        <img src="/assets/img/logos/cordina-logo-transparent-bg.png" alt="Cordina Logo" width="200" />
                    </a>
                </div>
                {renderMenu()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(PageHeader)
