import React from 'react'

import { Rnd } from 'react-rnd'

class TrancheScaleBar extends React.Component {
    state = {
        width: '100%',
        height: 20,
        x: 0,
        y: 0
    }

    render = () => {
        let ticks = []
        for (let tick = 0; tick < Math.ceil(this.props.maxEmission); tick = tick + 1) {
            ticks.push(<div className={`scale-tick ${this.props.isDetails && 'details'} ${tick === 0 ? 'first' : ''}`} key={`tick-${tick}`} style={{ left: `${(tick / this.props.maxEmission) * 100}%` }} />)
        }
        return (
            <div className="scale-bar-wrapper">
                <div className="scale-label">
                    <span>Carbon Output Scale</span>
                    tCO<sub>2</sub>e / t product
                </div>
                <div className="scale-bar">
                    <div className={`center-line ${this.props.isDetails && 'details'}`} />
                    {ticks}
                    <Rnd
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // borderLeft: 'solid 1px #FFF',
                            // borderRight: 'solid 5px #17373C',
                            // borderTop: 'solid 2px #FFFFFF',
                            // borderBottom: 'solid 2px #FFFFFF',
                            color: '#000',
                            // background: this.props.barStyle ? this.props.barStyle : 'rgba(35, 85, 87, .9)',
                            background: this.props.barStyle ? this.props.barStyle : '#18373A',
                            height: '100%'
                        }}
                        resizeHandleClasses={{ left: 'resize-handle', right: 'resize-handle' }}
                        bounds="parent"
                        minWidth="85px"
                        dragAxis="x"
                        enableResizing={{
                            top: false,
                            right: true,
                            bottom: false,
                            left: true,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false
                        }}
                        size={{ width: this.state.width, height: this.state.height }}
                        position={{ x: this.state.x, y: this.state.y }}
                        onDragStop={(e, d) => {
                            this.setState({ x: d.x, y: d.y })
                        }}
                        onResize={(e, direction, ref, delta, position) => {
                            const parentWidth = ref.parentElement.clientWidth
                            const scaleWidth = ref.style.width
                            const leftPos = position.x
                            this.props.onAdjustScale(parentWidth, scaleWidth, leftPos)
                        }}
                        onDrag={(e, data) => {
                            this.props.onAdjustPosition(data.x, data.node.style.width, data.node.parentElement.clientWidth)
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                            this.setState({
                                width: ref.style.width,
                                height: ref.style.height,
                                ...position
                            })
                        }}>
                        <div className="scale-values">
                            <span>{((this.props.scaledMin / 100) * this.props.maxEmission).toFixed(1)}</span>
                            <span>{(this.props.scaledMax / 100 * this.props.maxEmission).toFixed(1)}</span>
                        </div>
                    </Rnd>
                </div>
            </div>
        )
    }
}

export default TrancheScaleBar
