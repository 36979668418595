import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {}

const preAuthSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        savePreAuth(state, action) {
            state.authCode = action.payload.authCode
            state.fullName = action.payload.fullName
            state.organization = action.payload.organization
            state.pendingCreation = true
        },
        removePreAuth: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { savePreAuth, removePreAuth } = preAuthSlice.actions
export default preAuthSlice.reducer
