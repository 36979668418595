import React, { useState } from 'react'
import { connect } from 'react-redux'

import { verifyEmail } from '../../actions/auth'

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const VerifyEmailBar = ({ dispatch, auth, fireauth }) => {
    const [ showVerifyBar, setShowVerifyBar ] = useState(true)

    const sendEmailVerification = () => {
        setShowVerifyBar(false)
        dispatch(verifyEmail(fireauth))
        alert('Email verification sent. Please check your email.')
    }

    if (auth.emailVerified || !showVerifyBar) {
        return null
    }

    return (
        <div className="verify-bar">
            <span className="btn mint" onClick={sendEmailVerification}>Verify Your Email</span>
        </div>
    )
}

export default connect(mapStateToProps)(VerifyEmailBar)
