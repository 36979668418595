import React from 'react'
import shortid from 'shortid'
import { connect } from 'react-redux'

import { upsertForm, clearForm } from '../../reducers/form'
import { formStates } from '../../constants/helper-states'


class SHSForm extends React.Component {
    state = {
        formId: shortid.generate()
    }

    componentDidMount = () => {
        this.props.dispatch(upsertForm({ id: this.state.formId, status: formStates.EMPTY }))
    }

    componentWillUnmount = () => {
        this.props.dispatch(clearForm(this.state.formId))
    }

    componentDidUpdate = (prevProps) => {
        const formStatus = this.props.forms.find(form => (form.id === this.state.formId))
        const prevFormStatus = prevProps.forms.find(form => (form.id === this.state.formId))
        if (formStatus && formStatus.status === formStates.SUCCESS && prevFormStatus.status !== formStates.SUCCESS) {
            if (this.props.resetForm === true) {
                this.props.dispatch(upsertForm({ id: this.state.formId, status: formStates.EMPTY }))
            }
            this.props.successFX(formStatus.body)
        }
    }

    handleChange = (evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        this.setState({ [evt.target.id]: value })
    }

    handleSubmit = (evt) => {
        evt.preventDefault()
        let validated = evt.target.checkValidity()
        evt.target.classList.remove('was-validated')
        if (!validated) {
            evt.target.classList.add('was-validated')
        } else {
            const { formId } = this.state
            const formDataObj = new FormData(evt.target)
            this.props.dispatch(upsertForm({ id: formId, status: formStates.PROCESSING }))
            if (this.props.useFormData) {
                // this is used when we need to send raw file data
                this.props.submitFX(formDataObj, formId)
            } else {
                let formData = {}
                formDataObj.forEach((val, key) => {
                    let raw = document.getElementById(key).getAttribute('rawvalue')
                    if (raw !== '' && raw !== null) {
                        formData[key] = raw
                    } else {
                        formData[key] = val
                    }
                })
                this.props.submitFX(formData, formId)
            }
        }
    }

    handleCancel = (evt) => {
        evt.preventDefault()
        this.props.cancelAction()
    }

    render = () => {
        const formStatus = this.props.forms.find(stateForm => stateForm.id === this.state.formId)
        const buttonLabel = this.props.buttonLabel || 'Submit'
        return (
            <form onSubmit={this.handleSubmit} className={`form ${this.props.extraClass || ''}`} noValidate>
                {this.props.children}
                {formStatus && formStatus.status === 'ERROR' && formStatus.body !== '' &&
                    <div className="alert alert-danger w-100">
                        {formStatus.body}
                    </div>
                }
                <div className={`submit ${this.props.formClass || ''}`}>
                    <button
                        type="submit"
                        className={`btn green ${this.props.buttonClass || ''}`}
                        disabled={formStatus && (formStatus.status === 'PROCESSING' || formStatus.status === 'SUCCESS' || this.props.buttonDisabled) ? 'disabled' : null} >
                        {formStatus && formStatus.status === 'SUCCESS' ? 'Redirecting...' : buttonLabel}
                    </button>
                    { this.props.showCancel &&
                        <button className={`btn add clickable ml-2 ${this.props.buttonClass || ''}`} onClick={this.handleCancel}>
                            { this.props.cancelButtonLabel || 'Cancel' }
                        </button>
                    }
                </div>
            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        forms: state.forms
    }
}

export default connect(mapStateToProps)(SHSForm)
