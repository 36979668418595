import React from 'react'
import { connect } from 'react-redux'
import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import { confirmSignInWithEmailLink } from '../../actions/auth'
import { withRouterProps } from '../utils/with-router-props'

const ConfirmEmailSignIn = (props) => {
    const { dispatch, fireauth } = props

    const submitFX = (formData, formId) => {
        dispatch(confirmSignInWithEmailLink(fireauth, formData.emailConfirm, window.location.href, formId))
    }

    const successFX = () => {

    }

    return (
        <>
            <div className="login">
                <h1>Confirm Email</h1>
                <p>
                    To login, click the button below.
                </p>
                <div className="form-holder">
                    <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Confirm">
                        <InputElement
                            type="email"
                            id="emailConfirm"
                            label="Email"
                            placeholder="user@domain.com"
                            required />
                    </SHSForm>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect()(ConfirmEmailSignIn))
