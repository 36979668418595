import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from '../../constants/routes'

import Home from './home'
import Login from './login'
import Register from './register'
import ConfirmEmailSignin from './confirm-email-signin'
import NotFound from './not-found'


const MarketingIndex = ({ fireauth }) => {
    return (
        <div id="marketing-wrapper">
            <div className="container">
                <Routes>
                    <Route path={routes.authLogin} element={<Login fireauth={fireauth} />} />
                    <Route path={routes.authRegister} element={<Register fireauth={fireauth} />} />
                    <Route path={routes.confirmEmailSignIn} element={<ConfirmEmailSignin fireauth={fireauth} />} />
                    <Route index element={<Home fireauth={fireauth} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </div>
    )
}

export default MarketingIndex
