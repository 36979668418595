import { createSlice } from '@reduxjs/toolkit'
import * as helpers from './helpers'

const genericSlice = (sliceName, defaultPk = 'id') => {
    return createSlice({
        name: sliceName,
        initialState: [],
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(`${sliceName}/singleAction`, (state, action) => {
                    const model = action.data
                    const objectIdx = helpers.getObjectIndex(state, model[defaultPk], defaultPk)
                    if (objectIdx === -1) {
                        state.push(model)
                    } else {
                        state[objectIdx] = model
                    }
                })
                .addCase(`${sliceName}/listAction`, (state, action) => {
                    action.data.forEach(model => {
                        const objectIdx = helpers.getObjectIndex(state, model[defaultPk], defaultPk)
                        if (objectIdx === -1) {
                            state.push(model)
                        } else {
                            state[objectIdx] = model
                        }
                    })
                })
                .addCase('KILL_STATE', (state, action) => {
                    return []
                })
        }
    }).reducer
}

export default genericSlice
