import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'

class AppDashboard extends React.Component {
    state = {}

    render = () => {
        return (
            <div>
                <h1>Welcome to Cordina</h1>
                <p>
                    Start by exploring the <Link to={routes.trancheIndex}>Tranche Explorer</Link>.
                </p>
            </div>
        )
    }
}

export default AppDashboard
