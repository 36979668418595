import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../constants/routes'

import AppDashboard from './dashboard'
import CreateRoute from './routes/create'
import TrancheIndex from './tranches/index'
import VerifyEmailBar from '../auth/verify-email-bar'

class AppIndex extends React.Component {
    state = {}

    render = () => {
        return (
            <>
                <VerifyEmailBar fireauth={this.props.fireauth} />
                <div id="app-wrapper">
                    <div id="content" className="container">
                        <div className="row">
                            <div className="col">
                                <Routes>
                                    <Route path={nestedRoutePath(routes.appIndex, routes.routeCreate)} element={<CreateRoute />} />
                                    <Route path={nestedRoutePath(routes.appIndex, routes.trancheIndex)} element={<TrancheIndex />} />
                                    <Route index element={<AppDashboard />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AppIndex
