import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import TrancheDetails from './details/tranche-details'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectFacilities = state => state.facilities
const selectSubEmissions = state => state.subEmissions
const selectTranchePK = (state, tranchePK) => tranchePK

const makeSubEmisionsFromTranche = createSelector(
    [selectSubEmissions, selectTranchePK],
    (subEmissions, tranchePK) => {
        return subEmissions.filter(se => se.tranche_pk === tranchePK)
    })

const mapStateToProps = (state, ownProps) => {
    return {
        subEmissions: makeSubEmisionsFromTranche(state, ownProps.tranche.tranche_pk),
        facilities: selectFacilities(state)
    }
}

const TrancheChart = ({ dispatch, tranche, emissions, scaledMin, scaledMax, maxEmission, subEmissions, facilities }) => {
    const [expanded, setExpanded] = useState(false)
    const [activeMarker, setActiveMarker] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [hasFetchedSub, setHasFetchedSub] = useState(false)
    const [activeFacilities, setActiveFacilities] = useState([])
    const [facilityColors, setFacilityColors] = useState([{ colorCode: 'green', isAssigned: false }, { colorCode: 'blue', isAssigned: false }, { colorCode: 'yellow', isAssigned: false }, { colorCode: 'red', isAssigned: false }])
    const tranchePk = tranche.tranche_pk

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('tranches', 'VIEW', tranchePk), {
            expand: ['emissions', 'emissions.facility', 'emissions.product']
        }))
    }, [dispatch, tranchePk])

    useEffect(() => {
        if (showDetails && !hasFetchedSub) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('tranches', 'VIEW', tranchePk), {
                expand: ['emissions.sub-tranche-emissions']
            }))
            setHasFetchedSub(true)
        }
    }, [dispatch, tranchePk, showDetails, hasFetchedSub])

    const handleFacilityToggle = (facility) => {
        let stateFacilities = [...activeFacilities]
        let stateColors = [...facilityColors]
        const facilityIdx = stateFacilities.findIndex(f => f.facilityId === facility.facility_pk)
        if (facilityIdx === -1) {
            let availableColor
            if (stateColors.length > 0) {
                availableColor = stateColors.find(x => x.isAssigned === false)

                if (availableColor) {
                    stateFacilities.push({
                        facilityId: facility.facility_pk,
                        facilityName: facility.cordina_facility_name,
                        color: availableColor.colorCode
                    })
                    stateColors = stateColors.map(x => (x.colorCode === availableColor.colorCode ? { ...x, isAssigned: true } : x))
                }
            }
        } else {
            let savedFacility = stateFacilities.splice(facilityIdx, 1)
            stateColors = stateColors.map(x => (x.colorCode === savedFacility[0].color ? { ...x, isAssigned: false } : x))
        }
        setActiveFacilities(stateFacilities)
        setFacilityColors(stateColors)
    }

    const subEmissionData = useMemo(() => {
        let subData = {}
        subEmissions.forEach(emission => {
            Object.keys(emission.emissions).forEach((em, idx) => {
                let emValue = emission.emissions[em]
                if (em in subData) {
                    subData[em].entries.push({
                        value: emValue,
                        facility: emission.cordina_facility_name,
                        facilityId: emission.facility_pk,
                        confidence: emission.confidence,
                        idx: idx,
                        pk: `${emission.emissions_pk}-${idx}`
                    })
                    subData[em].sum = subData[em].sum + emValue
                } else {
                    subData[em] = {
                        name: em.replaceAll('_', ' '),
                        entries: [{
                            value: emValue,
                            facility: emission.cordina_facility_name,
                            facilityId: emission.facility_pk,
                            confidence: emission.confidence,
                            idx: idx,
                            pk: `${emission.emissions_pk}-${idx}`
                        }],
                        sum: emValue
                    }
                }
            })
        })
        return subData
    }, [subEmissions])

    const toggleMarkerNotes = (emission) => {
        if (emission.emissions_pk === activeMarker) {
            setActiveMarker(null)
        } else {
            setActiveMarker(emission.emissions_pk)
        }
    }

    const renderTrancheBar = () => {
        let markers = []
        let ticks = []
        let pops = []
        let trancheEmissions = emissions.filter(e => e.tranche_pk === tranche.tranche_pk)
        let maxLeft = 0
        for (let tickCount = 0; tickCount <= Math.ceil(maxEmission); tickCount = tickCount + 1) {
            let tickPercent = (tickCount / maxEmission) * 100
            let tickLeft = (tickPercent - scaledMin) / (scaledMax - scaledMin) * 100
            ticks.push(
                <div key={`tickCount-${tickCount}`} className="tick" style={{ left: `${tickLeft}%` }} />
            )
        }
        trancheEmissions
            .sort((a, b) => {
                if (a.tranche_co2e_prod_basis > b.tranche_co2e_prod_basis) {
                    return 1
                }
                return -1
            })
            .forEach((emission, idx) => {
                let actualEmissionPercent = (emission.tranche_co2e_prod_basis / maxEmission) * 100
                let left = (actualEmissionPercent - scaledMin) / (scaledMax - scaledMin) * 100
                // for calculating the sub emission bar
                if (left > maxLeft) {
                    maxLeft = left
                }
                let markerClass = ''
                if (emission.confidence > 0) {
                    markerClass = 'solid'
                }
                const facility = facilities.find(f => f.facility_pk === emission.facility_pk)
                markers.push(
                    <div
                        className={`marker ${markerClass}`}
                        title={facility.cordina_facility_name}
                        key={emission.emissions_pk}
                        onClick={() => toggleMarkerNotes(emission)}
                        style={{ left: `${left}%` }} />
                )
                if (activeMarker === emission.emissions_pk) {
                    let confidenceString = emission.confidence
                    if (confidenceString === 0) {
                        confidenceString = 'Low'
                    } else if (confidenceString === 1) {
                        confidenceString = 'Medium'
                    } else if (confidenceString === 2) {
                        confidenceString = 'High'
                    }
                    pops.push(
                        <div
                            className="pop"
                            onClick={() => setActiveMarker(null)}
                            // style={{ left: `${left}%`, bottom: `${expanded ? (trancheEmissions.length - idx) * 2.33 : 2}rem` }}
                            style={{ left: `${left}%`, bottom: `2rem` }}
                            key={`${emission.emissions_pk}-popover`}>
                            <span className="label">Facility</span>
                            <span className="value">{facility.cordina_facility_name}</span>
                            <span className="label">Emissions</span>
                            <span className="value">{emission.tranche_co2e_prod_basis} tCO<sub>2</sub>e / t product</span>
                            <span className="label">Confidence</span>
                            <span className="value">{confidenceString}</span>
                        </div>
                    )
                }
            })
        return (
            <div className="tranche-bar-holder">
                <div className="popover-bar">
                    {pops}
                </div>
                <div className="tranche-bar-wrapper">
                    <div className={`tranche-bar`}>
                        {ticks}
                        {markers}
                    </div>
                </div>
            </div>
        )
    }

    const renderExpandedTranche = () => {
        if (!expanded) {
            return null
        }
        // let markers = []
        let ticks = []
        let maxLeft = 0
        for (let tickCount = 0; tickCount <= Math.ceil(maxEmission); tickCount = tickCount + 1) {
            let tickPercent = (tickCount / maxEmission) * 100
            let tickLeft = (tickPercent - scaledMin) / (scaledMax - scaledMin) * 100
            ticks.push(
                <div key={`tickCount-${tickCount}`} className="tick" style={{ left: `${tickLeft}%` }} />
            )
        }
        let allFacilityEmissions = []
        emissions.filter(e => e.tranche_pk === tranche.tranche_pk)
            .sort((a, b) => {
                if (a.cordina_facility_name > b.cordina_facility_name) {
                    return 1
                }
                return -1
            })
            .forEach(emission => {
                let actualEmissionPercent = (emission.tranche_co2e_prod_basis / maxEmission) * 100
                let left = (actualEmissionPercent - scaledMin) / (scaledMax - scaledMin) * 100
                // for calculating the sub emission bar
                if (left > maxLeft) {
                    maxLeft = left
                }
                let markerClass = ''
                if (emission.confidence > 0) {
                    markerClass = 'solid'
                }
                const facility = facilities.find(f => f.facility_pk === emission.facility_pk)
                let activeFacility = activeFacilities.find(f => f.facilityId === emission.facility_pk)
                let activeColor = ''
                if (activeFacility) {
                    activeColor = activeFacility.color
                }
                allFacilityEmissions.push(
                    <div key={emission.emissions_pk} className="tranche-chart vertical">
                        <div className="tranche-label clickable" onClick={() => handleFacilityToggle(facility)}>
                            {facility.cordina_facility_name}
                        </div>
                        <div className="tranche-bar-holder">
                            <div className="tranche-bar-wrapper">
                                <div className="tranche-bar">
                                    {ticks}
                                    <div
                                        className={`marker ${markerClass} ${activeColor}`}
                                        title={facility.cordina_facility_name}
                                        key={emission.emissions_pk}
                                        onClick={() => handleFacilityToggle(facility)}
                                        style={{ left: `${left}%` }} />
                                </div>
                            </div>
                        </div>
                        <div className="actions">&nbsp;</div>
                    </div>
                )
            })
        return (
            <div className="expanded-tranche">
                <h2>Facilities List</h2>
                <div className="expanded-emissions">
                    {allFacilityEmissions}
                </div>
            </div>
        )
    }

    const renderTrancheDetails = () => {
        if (!showDetails) {
            return null
        }
        return (
            <TrancheDetails
                tranche={tranche}
                subEmissions={subEmissions}
                subEmissionData={subEmissionData}
                activeFacilities={activeFacilities}
                onHandleFacilityToggle={handleFacilityToggle} />
        )
    }

    return (
        <>
            <div className={`tranche-chart`}>
                <div className={`tranche-label-container`}>
                    <div className="tranche-label">
                        <span>{tranche.tranche}</span>
                    </div>
                    <div className="actions">
                        <span className={`expander ${showDetails ? 'active' : ''}`} onClick={() => setShowDetails(!showDetails)}>
                            <FontAwesomeIcon icon="list" />
                        </span>
                    </div>
                </div>
                {renderTrancheBar()}
                <div className="actions">
                    <span className={`expander ${expanded ? 'active' : ''}`} onClick={() => setExpanded(!expanded)}>
                        {expanded && <FontAwesomeIcon icon="chevron-up" />}
                        {!expanded && <FontAwesomeIcon icon="chevron-down" />}
                    </span>
                    <span className={`expander ${showDetails ? 'active' : ''}`} onClick={() => setShowDetails(!showDetails)}>
                        <FontAwesomeIcon icon="list" />
                    </span>
                </div>
            </div>
            {renderExpandedTranche()}
            {renderTrancheDetails()}
        </>
    )
}

export default connect(mapStateToProps)(TrancheChart)
