import React from 'react'

import debounce from 'lodash/debounce'

export class Autocomplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            query: '',
            options: null
        }
        this._debounceQuery = debounce(this.fetchOptions, 500)
    }

    callMapboxQuery = async (searchText) => {
        let mapBoxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
        const mapBoxParams = {
            access_token: process.env.REACT_APP_MAPBOX_PUBLIC_KEY,
            language: 'en',
            types: 'place,address',
            autocomplete: true
        }
        let query = ''
        query = Object.keys(mapBoxParams).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(mapBoxParams[key])
        }).join('&')
        if (query !== '') {
            query = `?${query}`
        }
        let fetchUrl = `${mapBoxUrl}${searchText}.json${query}`
        const results = await fetch(fetchUrl).then(response => {
            return response.json()
        }).then(result => {
            return result
        })
        this.setState({ options: results.features })
    }

    fetchOptions = () => {
        const { query } = this.state
        if (query.length >= 2) {
            this.callMapboxQuery(query)
        } else {
            this.clearOptions()
        }
    }

    onChange = (e) => {
        this.setState({ query: e.target.value })
        this._debounceQuery()
    }

    onKeyDown = (e) => {
        // 13 RETURN
        // 9 TAB, prob can't use...
        if (e.keyCode === 27) {
            // clear on ESC
            this.clearSearch()
        }
    }

    clearSearch = () => {
        // clearTimeout(this.timer)
        this.setState({ query: '', options: null })
    }

    clearOptions = () => {
        this.setState({ options: null })
    }

    onSelect = (item) => {
        this.props.onSelect(item)
        this.clearSearch()
    }

    onCreateAction = (term) => {
        this.props.onCreateAction(term)
        this.clearSearch()
    }

    renderOptions = () => {
        let allOptions = []
        let showAdd = true
        if (this.state.options) {
            this.state.options.forEach((item, i) => {
                allOptions.push(
                    <div key={i} tabIndex={i} className="item" onClick={() => this.onSelect(item)}>
                        {this.props.formatOptions(item)}
                    </div>
                )
            })
            if (this.props.matchField && this.props.matchField !== '') {
                showAdd = (this.state.options.findIndex(item => {
                    return item[this.props.matchField].toUpperCase() === this.state.query.toUpperCase()
                }) === -1 ? true : false)
            }
        }

        if (!this.props.disableAddOption && showAdd) {
            allOptions.push(
                <div className="item" key={this.state.options.length + 1} onClick={() => this.onCreateAction(this.state.query)}>
                    <em>Add <strong>"{this.state.query}"</strong></em>
                </div>
            )
        }

        if (allOptions.length === 0) {
            return <div>No Results</div>
        }
        return allOptions
    }

    render = () => {
        return (
            <>
                <div className="search">
                    <div className="search-field input-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            value={this.state.query}
                            placeholder="Search" />
                    </div>
                    {this.state.options &&
                        <>
                            <div className="search-result-options">
                                {this.renderOptions()}
                            </div>
                            <div className="search-overlay" onClick={this.clearSearch} />
                        </>
                    }
                </div>
            </>
        )
    }
}

export default Autocomplete
