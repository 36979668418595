import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

// import InputElement from '../../../components/utils/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectValueChains = (state) => state.valueChains

const mapStateToProps = (state) => {
    return {
        valueChains: selectValueChains(state)
    }
}

const ValueChainSelector = ({ dispatch, valueChains, onSetValueChainId, activeValueChainId }) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('value-chains', 'LIST')))
    }, [dispatch])

    const handleChainSelection = (value) => {
        onSetValueChainId(value)
        setIsOpen(false)
    }

    const renderVCSelector = () => {
        const valueChain = valueChains.find(vc => vc.value_chain_pk === Number(activeValueChainId))
        let chainName = valueChain ? <strong>{valueChain.value_chain}</strong> : 'Select a Value Chain'
        let options = []
        valueChains.forEach((chain, idx) => {
            options.push(
                <div className="option" key={idx} onClick={() => handleChainSelection(chain.value_chain_pk)}>{chain.value_chain}</div>
            )
        })
        return (
            <div className="selector-wrapper">
                <span className="selector" onClick={() => setIsOpen(!isOpen)}>
                    {chainName} <FontAwesomeIcon icon="chevron-down" />
                </span>
                { isOpen &&
                    <div className="options">
                        {options}
                    </div>
                }
            </div>
        )
    }

    if (!valueChains || valueChains.length === 0) {
        return null
    }
    return (
        <div className="value-chain-header">
            <div className="header">
                <h1>Value Chain</h1>
                {renderVCSelector()}
            </div>
            {/* <div className="controls">
                <span className="selector">
                    <FontAwesomeIcon icon="key" /> Ref Key
                </span>
                <span className="selector">
                    <FontAwesomeIcon icon="sliders-h" /> Filters
                </span>
            </div> */}
        </div>
    )
}

export default connect(mapStateToProps)(ValueChainSelector)
