import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import TrancheScaleBar from '../scale-bar'

import { fetchApiDataIfNeeded } from '../../../../actions/api'
import { apiResourceEndpoint } from '../../../../constants/routes'

import isEmpty from 'lodash/isEmpty'
import { toDecimals } from '../../../utils/formatters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// selectors
const selectSubTranches = (state) => state.subTranches
const selectEmissions = (state) => state.emissions
const selectSubEmissions = (state) => state.subEmissions
const selectFacilities = (state) => state.facilities
const selectTranchePK = (state, tranchePK) => tranchePK
const selectEmissionsPKs = (state, emissions) => emissions.map(e => e.emissions_pk)
const selectFacilityPKsFromEmissions = (state, emissions) => emissions.map(e => e.facility_pk)

// createSelectors
const makeSubTranchesFromTranchePK = createSelector(
    [selectSubTranches, selectTranchePK],
    (subTranches, tranchePK) => {
        return subTranches.filter(st => st.tranche_pk === tranchePK)
            .sort((a, b) => {
                if (a.label > b.label) {
                    return 1
                }
                return -1
            })
    }
)
const makeEmissionsFromTranchPK = createSelector(
    [selectEmissions, selectTranchePK],
    (emissions, tranchePK) => {
        return emissions.filter(e => e.tranche_pk === tranchePK)
    }
)
const makeSubEmisionsFromEmissionsPKs = createSelector(
    [selectSubEmissions, selectEmissionsPKs],
    (subEmissions, emissionsPKs) => {
        return subEmissions.filter(e => emissionsPKs.indexOf(e.emissions_pk) !== -1)
    }
)
const makeFacilitiesFromFacilityPKs = createSelector(
    [selectFacilities, selectFacilityPKsFromEmissions],
    (facilities, facilityPKs) => {
        return facilities.filter(f => facilityPKs.indexOf(f.facility_pk) !== -1)
    }
)

const mapStateToProps = (state, ownProps) => {
    const subTranches = makeSubTranchesFromTranchePK(state, ownProps.tranche.tranche_pk)
    const emissions = makeEmissionsFromTranchPK(state, ownProps.tranche.tranche_pk)
    const subEmissions = makeSubEmisionsFromEmissionsPKs(state, emissions)
    const facilities = makeFacilitiesFromFacilityPKs(state, emissions)
    return { subTranches, emissions, subEmissions, facilities }
}

const TrancheDetails = ({ dispatch, tranche, subTranches, emissions, subEmissions, facilities, activeFacilities, onHandleFacilityToggle }) => {
    const [scaledMin, setScaledMin] = useState(0)
    const [ scaledMax, setScaledMax ] = useState(100)
    const tranchePk = tranche.tranche_pk

    const subEmissionMax = useMemo(() => {
        let max = 0
        subTranches.forEach(subTranch => {
            subEmissions.forEach(subEmission => {
                let subEmissionValue = subEmission[subTranch.sub_tranche_key]
                if (subEmissionValue > max) {
                    max = subEmissionValue
                }
            })
        })
        return max
    }, [subEmissions])

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('sub-tranches', 'LIST', tranchePk), { tranche_id: tranchePk }))
    }, [dispatch, tranchePk])

    const toggleMarkerNotes = (facility) => {
        onHandleFacilityToggle({
            facility_pk: facility.facility_pk,
            cordina_facility_name: facility.cordina_facility_name
        })
    }

    const renderTrancheBar = (subEmissionKey) => {
        // loop over the entries and map them into the bar...
        let markers = []
        let ticks = []
        // let pops = []
        for (let tickCount = 0; tickCount <= Math.ceil(subEmissionMax); tickCount = tickCount + 1) {
            let tickPercent = (tickCount / subEmissionMax) * 100
            let tickLeft = (tickPercent - scaledMin) / (scaledMax - scaledMin) * 100
            ticks.push(
                <div key={`tickCount-${tickCount}`} className="tick" style={{ left: `${tickLeft}%` }} />
            )
        }

        subEmissions.forEach((subEmission, idx) => {
            const parentEmission = emissions.find(e => e.emissions_pk === subEmission.emissions_pk)
            const facility = facilities.find(f => f.facility_pk === parentEmission.facility_pk)
            let actualEmissionPercent = (subEmission[subEmissionKey] / subEmissionMax) * 100
            let left = (actualEmissionPercent - scaledMin) / (scaledMax - scaledMin) * 100
            let markerClass = 'solid'
            // if (emission.confidence > 0) {
            //     markerClass = 'solid'
            // }
            let activeFacility = activeFacilities.find(f => f.facilityId === facility.facility_pk)
            let activeColor = ''
            if (activeFacility) {
                activeColor = activeFacility.color
            }
            markers.push(
                <div
                    className={`marker ${markerClass} ${activeColor}`}
                    title={facility.cordina_facility_name}
                    key={idx}
                    onClick={() => toggleMarkerNotes(facility)}
                    style={{ left: `${left}%` }} />
            )
        })
        return (
            <div className="tranche-bar-holder">
                {/* <div className="popover-bar">
                    {pops}
                </div> */}
                <div className="tranche-bar-wrapper">
                    <div className="tranche-bar">
                        {ticks}
                        {markers}
                    </div>
                </div>
            </div>
        )
    }

    const renderSubTranches = () => {
        let allSubs = []
        subTranches.forEach(subTranche => {
            allSubs.push(
                <div className="tranche-chart" key={subTranche.sub_tranche_pk}>
                    <div className="tranche-label">
                        {subTranche.label}
                    </div>
                    {renderTrancheBar(subTranche.sub_tranche_key)}
                    <div className="actions" />
                </div>
            )
        })
        return allSubs
    }

    const adjustPosition = (posX, scaleWidth, parentWidth) => {
        let sMin = (posX / parentWidth) * 100
        let sMax = ((posX + (parseFloat(scaleWidth) / 100 * parentWidth)) / parentWidth) * 100
        setScaledMin(sMin)
        setScaledMax(sMax)
    }

    const adjustScale = (parentWidth, scaleWidth, offsetX) => {
        let sMin = (offsetX / parentWidth) * 100
        let sMax = ((offsetX + (parseFloat(scaleWidth) / 100 * parentWidth)) / parentWidth) * 100
        setScaledMax(sMax)
        setScaledMin(sMin)
    }

    const renderFacilitiesTable = () => {
        if (activeFacilities.length === 0) {
            return null
        }
        let allFacilities = []
        let headers = [<th key="blank-header">&nbsp;</th>]
        subTranches.forEach(sub => {
            headers.push(
                <th key={sub.sub_tranche_pk}>{sub.label}</th>
            )
        })
        activeFacilities.forEach((facility, idx) => {
            let facilityValues = []
            const emission = emissions.find(e => e.facility_pk === facility.facilityId)
            const subEmission = subEmissions.find(e => e.emissions_pk === emission?.emissions_pk)
            if (subEmission) {
                subTranches.forEach((sub, sIdx) => {
                    let eValue = subEmission[sub.sub_tranche_key]
                    if (eValue) {
                        facilityValues.push(
                            <td key={sIdx} className="emission-value">
                                {toDecimals(eValue, 4)}
                            </td>
                        )
                    }
                })
            }
            allFacilities.push(
                <tr key={idx}>
                    <td>
                        <span className={`active-facility ${facility.color}`}>
                            {facility.facilityName}
                        </span>
                    </td>
                    {facilityValues}
                </tr>
            )
        })
        return (
            <table className="table facilities">
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>{allFacilities}</tbody>
            </table>
        )
    }

    const renderActiveFacilities = () => {
        let allActives = []
        activeFacilities.forEach(facility => {
            allActives.push(
                <div key={facility.facilityId} className={`toggle-btn ${facility.color}`}>
                    {facility.facilityName}
                    <span className="closer" onClick={() => onHandleFacilityToggle({ facility_pk: facility.facilityId, cordina_facility_name: facility.facilityName })}>
                        <FontAwesomeIcon icon="times-circle" />
                    </span>
                </div>
            )
        })
        return (
            <div className="active-facilities-wrapper">
                <div className="tranche-name">
                    {tranche.tranche} Details
                </div>
                <div className="actives">
                    {allActives}
                </div>
                <div className="action">&nbsp;</div>
            </div>
        )
    }

    return (
        <div className="tranche-details">
            {isEmpty(subEmissions)
                ? <div className="detail-content">Loading sub emissions...</div>
                : <div className="detail-content">
                    {renderActiveFacilities()}
                    <TrancheScaleBar
                        minEmission={0}
                        maxEmission={subEmissionMax}
                        scaledMin={scaledMin}
                        scaledMax={scaledMax}
                        barStyle="rgba(23, 55, 60, .9)"
                        isDetails={true}
                        onAdjustScale={adjustScale}
                        onAdjustPosition={adjustPosition} />
                    <div className="chart-holder">
                        {renderSubTranches()}
                    </div>
                    {renderFacilitiesTable()}
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps)(TrancheDetails)
