import React from 'react'
// components
import TrancheScaleBar from './scale-bar'
import TrancheChart from './tranche-chart'

const ChartViewer = ({ tranches, minEmission, maxEmission, scaledMin, scaledMax, activeEmissions, onAdjustPosition, onAdjustScale }) => {
    const renderTranches = () => {
        let allHorizontal = []

        tranches.forEach(tranche => {
            allHorizontal.push(
                <TrancheChart
                    key={tranche.tranche_pk}
                    tranche={tranche}
                    emissions={activeEmissions}
                    minEmission={minEmission}
                    maxEmission={maxEmission}
                    scaledMin={scaledMin}
                    scaledMax={scaledMax} />)
        })

        return (
            <>
                <TrancheScaleBar
                    minEmission={minEmission}
                    maxEmission={maxEmission}
                    scaledMin={scaledMin}
                    scaledMax={scaledMax}
                    onAdjustScale={onAdjustScale}
                    onAdjustPosition={onAdjustPosition} />
                <div className="chart-holder">
                    {allHorizontal}
                </div>
            </>
        )
    }

    return (
        <div>
            {renderTranches()}
        </div>
    )
}

export default ChartViewer
