import apiCacheReducer from './api-cache'
import authReducer from './auth'
import formReducer from './form'
import genericSlice from './generic-slice'
import preAuthReducer from './pre-auth'

const indexReducer = {
    apiCaches: apiCacheReducer,
    auth: authReducer,
    emissions: genericSlice('emissions', 'emissions_pk'),
    facilities: genericSlice('facility', 'facility_pk'),
    forms: formReducer,
    preAuth: preAuthReducer,
    products: genericSlice('product', 'product_pk'),
    subEmissions: genericSlice('subTrancheEmissions', 'st_emissions_pk'),
    subsidiaries: genericSlice('subsidiaries', 'subsidiary_pk'),
    subTranches: genericSlice('subTranches', 'sub_tranche_pk'),
    tranches: genericSlice('tranches', 'tranche_pk'),
    valueChains: genericSlice('valueChains', 'value_chain_pk'),
    users: genericSlice('users', 'email')
}

export default indexReducer
