import { getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, sendEmailVerification } from 'firebase/auth'
import { formStates } from '../constants/helper-states'
import { upsertForm } from '../reducers/form'
import { routes } from '../constants/routes'

export const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

const saveAuth = (auth) => {
    return {
        type: `SAVE_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}

const saveAuthHandlerConfig = (code, action, email) => {
    return {
        type: `SAVE_AUTH_HANDLER`,
        code,
        action,
        email
    }
}

const saveAuthHandlerError = () => {
    return {
        type: 'AUTH_HANDLER_ERROR'
    }
}

export const clearAuthCode = () => {
    return {
        type: `REMOVE_AUTH_HANDLER`
    }
}

export const registerWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        createUserWithEmailAndPassword(fireauth, email, password)
            .then(registeredUser => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const handleFirebaseAuthCode = (fireauth, code, action) => {
    return dispatch => {
        fireauth.verifyPasswordResetCode(code)
            .then(email => {
                dispatch(saveAuthHandlerConfig(code, action, email))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleSignOut = () => {
    return dispatch => {
        const auth = getAuth()
        signOut(auth)
            .then(() => {
                dispatch(killState())
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const handleSendSignInLinkToEmail = (fireauth, email, formId) => {
    return dispatch => {
        const actionCodeSettings = { url: process.env.REACT_APP_WEBAPP_URL + routes.confirmEmailSignIn, handleCodeInApp: true }

        sendSignInLinkToEmail(fireauth, email, actionCodeSettings)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const confirmSignInWithEmailLink = (fireauth, email, url, formId) => {
    return dispatch => {
        if (isSignInWithEmailLink(fireauth, url)) {
            signInWithEmailLink(fireauth, email, url)
                .then(() => {
                    console.log('success with email signin')
                    dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
                })
                .catch((err) => {
                    console.log('An occurred during sign in: ', err.message)
                    dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
                })
        }
    }
}

export const verifyEmail = (fireauth, email) => {
    return dispatch => {
        const auth = getAuth()
        sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log('email sent')
            })
            .catch(err => {
                console.log(err)
            })
    }
}
