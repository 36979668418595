import { generatePath } from 'react-router-dom'

import { camelCase } from 'lodash'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    index: '/',
    router: '/router/',
    authLogin: '/login/',
    authRegister: '/register/',
    authLogout: '/logout/',
    authUserCreate: '/user-create/',
    authHandler: '/auth-handler/',
    appIndex: '/app/',
    confirmEmailSignIn: '/confirm-email-signin/',
    trancheIndex: '/app/tranche/',
    routeCreate: '/app/routes/create/',
    modalSample: '/:pagePath*/m/sample/'
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '', reduxKey = null) => {
    let routeObj = {
        url: `/${resource}/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    // build the reducer info
    let reducerKey = resource.split('/').at(-1)
    if (reduxKey) {
        reducerKey = reduxKey
    }
    routeObj.dataKey = camelCase(reducerKey)
    return routeObj
}

export const apiRoutes = {
    webGet: { url: '/', method: API_METHODS.GET }
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}
