import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'

import { apiResourceEndpoint, routes } from '../../constants/routes'
import { callApi, fetchApiDataIfNeeded } from '../../actions/api'

import { isEmpty } from 'lodash'

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        preAuth: state.preAuth,
        users: state.users
    }
}
const AuthRouter = ({ dispatch, auth, preAuth, users, location }) => {
    const [ hasCreatedUser, setHasCreatedUser ] = useState(false)
    const [ readyToRoute, setReadyToRoute ] = useState(false)
    const [ onboardStatus, setOnboardStatus ] = useState('')

    useEffect(() => {
        if (!isEmpty(auth) && 'authId' in auth) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('users', 'LIST', null, 'status')))
        }
    }, [dispatch, auth])

    useEffect(() => {
        if (users && users.length === 1 && users[0].auth_id) {
            const user = users[0]
            if (user.name && user.organization) {
                setReadyToRoute(true)
            } else if (!isEmpty(preAuth)) {
                if (user.id > 1) {
                    const userData = {
                        name: preAuth.fullName,
                        organization: preAuth.organization
                    }
                    dispatch(callApi(apiResourceEndpoint('users', 'CREATE', null, 'update'), userData, -1))
                }
            } else {
                setOnboardStatus('USER_CREATE')
            }
        } else if (!isEmpty(preAuth) && 'authCode' in preAuth && !hasCreatedUser) {
            const formData = {
                passcode: preAuth.authCode,
                name: preAuth.fullName,
                organization: preAuth.organization
            }
            dispatch(callApi(apiResourceEndpoint('users', 'CREATE'), formData, -1))
            setHasCreatedUser(true)
        }
    }, [dispatch, users, preAuth, hasCreatedUser])

    if (readyToRoute) {
        if (location.state && location.state.from.pathname) {
            return <Navigate to={location.state.from.pathname} />
        }
        return <Navigate to={routes.trancheIndex} />
    } else if (onboardStatus !== '') {
        switch (onboardStatus) {
        case 'USER_CREATE':
            return <Navigate to={routes.authUserCreate} />
        default:
            return <div className="router-msg">Loading....</div>
        }
    }
    return <div className="router-msg">Routing...</div>
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
