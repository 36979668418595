import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'

import { callPublicApi } from '../../actions/api'
import { apiResourceEndpoint } from '../../constants/routes'
import { signInWithPopup, OAuthProvider } from 'firebase/auth'
import { routes } from '../../constants/routes'

import { registerWithEmailAndPass } from '../../actions/auth'

import { savePreAuth } from '../../reducers/pre-auth'

const Register = ({ dispatch, fireauth }) => {
    // Hardcoded access code
    const accessCode = 'SCOPE3'

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [fullName, setFullName] = useState('')
    const [organization, setOrganization] = useState('')
    const [formId, setFormId] = useState('')

    const handleSignIn = () => {
        // const fireauth = getAuth()
        const provider = new OAuthProvider('microsoft.com')
        // provider.setCustomParameters({
        //     // prompt: 'consent', // this forces consent prompt EVERY time
        //     login_hint: 'user@cordina.io',
        //     tenant: '77ffd5bf-ed07-470c-97fa-ad09b0abde1d'
        // })
        signInWithPopup(fireauth, provider)
            .then((result) => {
                // do nothing for now because picked up by listener in app.js
                console.log('did this work?')
            })
            .catch((error) => {
                // Handle error.
            })
    }

    const submitFX = (formData, fId) => {
        setFormId(fId)
        const payload = {
            passcode: accessCode
        }
        dispatch(callPublicApi(apiResourceEndpoint('users/preauth', 'CREATE'), payload, fId))
        setEmail(formData.email)
        setPassword(formData.password)
        setFullName(formData.name)
        setOrganization(formData.organization)
    }

    const successFX = () => {
        dispatch(savePreAuth({ authCode: accessCode, fullName: fullName, organization: organization }))
        dispatch(registerWithEmailAndPass(fireauth, email, password, formId))
    }

    return (
        <>
            <div onClick={handleSignIn} className="btn w-100 text-center">
                Sign Up with Microsoft
            </div>
            <div>
                <SHSForm submitFX={submitFX} successFX={successFX}>
                    <p>Or register with email and password.</p>
                    <InputElement
                        type="text"
                        id="name"
                        label="Name"
                        placeholder="First Name and Last Name"
                        required />
                    <InputElement
                        type="email"
                        id="email"
                        label="Email"
                        placeholder="user@domain.com"
                        required />
                    <InputElement
                        type="password"
                        id="password"
                        label="Password"
                        placeholder="Enter your password"
                        required />
                    <InputElement
                        type="text"
                        id="organization"
                        label="Organization"
                        placeholder="Organization Name"
                        required />
                </SHSForm>
                <p className="mt-3">
                    Already registered? Sign in <Link to={routes.authLogin}>here</Link>.
                </p>
            </div>
        </>
    )
}

export default connect()(Register)
