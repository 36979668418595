import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../utils/with-router-props'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import { apiResourceEndpoint, routes } from '../../constants/routes'
import { callApi } from '../../actions/api'

const mapStateToProps = (state) => {
    return {
        users: state.users
    }
}

const CreateUser = ({ dispatch, users, navigate }) => {
    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('users', 'CREATE', '', 'update', 'USERS'), formData, formId))
    }

    const successFX = () => {
        navigate(routes.router)
    }

    return (
        <div className="login">
            <h1>Personal Information</h1>
            <div className="form-holder">
                <SHSForm submitFX={submitFX} successFX={successFX}>
                    <InputElement
                        type="text"
                        id="name"
                        label="Full Name"
                        placeholder="First and Last Name"
                        default={users.length === 1 ? users[0].name : ''}
                        required />
                    <InputElement
                        type="organization"
                        id="organization"
                        label="Organization"
                        placeholder="Organization Name"
                        required />
                </SHSForm>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(CreateUser))
