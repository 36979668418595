import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import ChartViewer from './chart-viewer'
// import ChartControls from './chart-controls'

const selectTranches = (state) => state.tranches
const selectEmissions = (state) => state.emissions
const selectActiveValueChainId = (state, activeValueChainId) => activeValueChainId
const selectTranchePKs = (state, tranches) => tranches.map(t => t.tranche_pk)

const makeTranchesFromValueChain = createSelector(
    [selectTranches, selectActiveValueChainId],
    (tranches, activeValueChainId) => {
        return tranches.filter(t => t.value_chain_pk === activeValueChainId)
            .sort((a, b) => {
                if (a.tranche_display_order > b.tranche_display_order) {
                    return 1
                }
                return -1
            })
    })

const makeEmissionsFromTranchePKs = createSelector(
    [selectEmissions, selectTranchePKs],
    (emissions, tranchePks) => {
        return emissions.filter(e => tranchePks.indexOf(e.tranche_pk) !== -1)
    })

const mapStateToProps = (state, ownProps) => {
    const tranches = makeTranchesFromValueChain(state, ownProps.activeValueChainId)
    const emissions = makeEmissionsFromTranchePKs(state, tranches)
    return { emissions, tranches }
}

const TrancheViewer = ({ dispatch, activeValueChainId, emissions, tranches }) => {
    const [ minEmission, setMinEmission ] = useState(0)
    const [ maxEmission, setMaxEmission ] = useState(0)
    const [ scaledMin, setScaledMin ] = useState(0)
    const [ scaledMax, setScaledMax ] = useState(100)
    const [ activeEmissions, setActiveEmissions ] = useState([])
    const [ activeLegends, setActiveLegends ] = useState(['HIGH', 'LOW', 'COMPANY', 'CORDINA'])
    const emissionsLength = emissions.length

    useEffect(() => {
        if (activeValueChainId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('tranches', 'LIST'), { value_chain_id: activeValueChainId }))
        }
    }, [dispatch, activeValueChainId])

    const updateStateEmissions = useCallback(() => {
        let lowIdx = activeLegends.indexOf('LOW')
        let highIdx = activeLegends.indexOf('HIGH')
        let filteredEmissions = emissions.filter(emission => {
            if (lowIdx === -1 && emission.confidence !== 1) {
                return false
            }
            if (highIdx === -1 && emission.confidence === 1) {
                return false
            }
            return true
        })
        filteredEmissions.forEach(emission => {
            if (emission.tranche_co2e_prod_basis > maxEmission) {
                setMaxEmission(emission.tranche_co2e_prod_basis)
            }
            if (emission.tranche_co2e_prod_basis < minEmission) {
                setMinEmission(emission.tranche_co2e_prod_basis)
            }
        })
        setActiveEmissions(filteredEmissions)
        setScaledMin(0)
        setScaledMax(100)
    }, [activeLegends, emissions, maxEmission, minEmission])

    useEffect(() => {
        if (emissionsLength) {
            updateStateEmissions()
        }
    }, [emissionsLength, updateStateEmissions])

    const adjustPosition = (posX, scaleWidth, parentWidth) => {
        setScaledMin((posX / parentWidth) * 100)
        setScaledMax(((posX + (parseFloat(scaleWidth) / 100 * parentWidth)) / parentWidth) * 100)
    }

    const adjustScale = (parentWidth, scaleWidth, offsetX) => {
        setScaledMin((offsetX / parentWidth) * 100)
        setScaledMax(((offsetX + (parseFloat(scaleWidth) / 100 * parentWidth)) / parentWidth) * 100)
    }

    const toggleLegend = (legend) => {
        let stateActiveLegends = [...activeLegends]
        const idx = stateActiveLegends.indexOf(legend)
        if (idx === -1) {
            stateActiveLegends.push(legend)
        } else {
            stateActiveLegends.splice(idx, 1)
        }
        setActiveLegends(stateActiveLegends)
        updateStateEmissions()
    }

    if (!activeValueChainId) {
        return null
    }
    const commonProps = {
        onToggleLegend: toggleLegend,
        onAdjustScale: adjustScale,
        onAdjustPosition: adjustPosition,
        tranches: tranches,
        activeLegends: activeLegends,
        activeEmissions: activeEmissions,
        minEmission: minEmission,
        maxEmission: maxEmission,
        scaledMax: scaledMax,
        scaledMin: scaledMin
    }
    return (
        <div className="viewer-wrapper">
            <div className="display-wrapper">
                <div className="chart-wrapper">
                    <ChartViewer {...commonProps}/>
                </div>
            </div>
            {/* <div className="controls-wrapper">
                <ChartControls {...commonProps} />
            </div> */}
        </div>
    )
}

export default connect(mapStateToProps)(TrancheViewer)
