import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faChevronDown, faChevronUp, faTimes, faCircle, faKey, faSlidersH, faList, faArrowsAltV, faTimesCircle
} from '@fortawesome/free-solid-svg-icons'

import {
    faDotCircle as farDotCircle, faCircle as farCircle
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faChevronDown, faChevronUp, faTimes, faCircle, faKey, faSlidersH, faList, faArrowsAltV, faTimesCircle,
    farCircle, farDotCircle
)
