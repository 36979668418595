import React from 'react'

class NotFound extends React.Component {
    state = {}

    render = () => {
        return (
            <div>The page you are looking for was not found</div>
        )
    }
}

export default NotFound
