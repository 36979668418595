import React from 'react'

import Register from './register'

const Home = ({ fireauth }) => {
    return (
        <div id="home">
            <div className="half">
                <h1>Try it and shape it!</h1>
                <p>
                    Sign-in to our Lithium beta to see what data could be developed for your industrial supply chain.
                </p>
                <p>
                    Your feedback to <a href="mailto:info@cordina.io" className="white">info@cordina.io</a> will directly influence further product development
                </p>
            </div>
            <div className="half">
                <div className="form-holder">
                    <Register fireauth={fireauth} />
                </div>
            </div>
        </div>
    )
}

export default Home
