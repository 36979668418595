import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { signInWithPopup, OAuthProvider } from 'firebase/auth'

import SHSForm from '../utils/shs-form'
import InputElement from '../utils/input'
import { routes } from '../../constants/routes'
import { handleSendSignInLinkToEmail, signInWithEmailAndPass } from '../../actions/auth'

const Login = (props) => {
    const { dispatch, fireauth } = props
    const [useLogin, setUseLogin] = useState(true)
    const [useLink, setUseLink] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    const handleSignIn = () => {
        // const fireauth = getAuth()
        const provider = new OAuthProvider('microsoft.com')
        // provider.setCustomParameters({
        //     login_hint: 'user@cordina.io'
        // })
        signInWithPopup(fireauth, provider)
            .then((result) => {
                // do nothing for now because picked up by listener in app.js
            })
            .catch((error) => {
                // Handle error.
            })
    }

    const submitFX = (formData, formId) => {
        if (useLink) {
            dispatch(handleSendSignInLinkToEmail(fireauth, formData.email, formId))
        } else {
            dispatch(signInWithEmailAndPass(fireauth, formData.email, formData.password, formId))
        }
    }

    const successFX = () => {
        if (useLink) {
            setShowMessage(true)
        }
    }

    const renderOAuthLogin = () => {
        return (
            <div className="oauth-links">
                <div onClick={handleSignIn} className="btn white">
                    Sign In with Microsoft
                </div>
                <span className="clickable" onClick={() => setUseLogin(true)}>
                    Sign in with Email
                </span>
            </div>
        )
    }

    const renderLoginForm = () => {
        return (
            <div className="form-holder">
                <SHSForm submitFX={submitFX} successFX={successFX}>
                    <InputElement
                        type="email"
                        id="email"
                        label="Email"
                        placeholder="user@domain.com"
                        required />
                    {!useLink &&
                        <InputElement
                            type="password"
                            id="password"
                            label="Password"
                            placeholder="Enter your password"
                            required />
                    }
                    <InputElement
                        type="checkbox"
                        id="signInWithEmail"
                        label="Sign with Email link instead"
                        onChange={() => setUseLink(!useLink)} />
                </SHSForm>
                <div className="sign-in-links">
                    <span className="clickable" onClick={() => setUseLogin(false)}>Sign in with Microsoft</span>
                    <Link to={routes.index}>Register</Link>
                </div>
            </div>
        )
    }

    return (
        <div className="login">
            <h1>LOGIN</h1>
            {showMessage
                ? <p>Please check your email for a link that will log you in.</p>
                : (
                    <>
                        <p>
                            Access to the Cordina portal is limited to accounts with an authorized email address.<br />
                            To login, click the button below.
                        </p>
                        {useLogin
                            ? renderLoginForm()
                            : renderOAuthLogin()
                        }
                    </>
                )
            }
        </div>
    )
}

export default connect()(Login)
