import React, { useState } from 'react'

import ValueChainSelector from './selector'
import TrancheViewer from './viewer'

import hexFill from '../../../assets/img/cordina-bee-grey.svg'

const TrancheIndex = () => {
    // Setting the Value chain default to Lithium
    const [valueChainId, setValueChainId] = useState(1)

    return (
        <div className="container">
            <ValueChainSelector
                activeValueChainId={valueChainId}
                onSetValueChainId={setValueChainId} />
            <TrancheViewer activeValueChainId={valueChainId} />
            { !valueChainId &&
                <div className="empty-bee">
                    <img src={hexFill} alt="Cordina Reference" />
                </div>
            }
        </div>
    )
}

export default TrancheIndex
