import React from 'react'
import { connect } from 'react-redux'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

import Autocomplete from '../../utils/autocomplete'

// import SHSForm from '../../utils/shs-form'

class CreateRoute extends React.Component {
    state = {
        place: '',
        long: null,
        lat: null
    }

    componentDidMount = () => {
        // this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('status', 'LIST')))
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('subsidiaries', 'LIST')))
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('subsidiaries', 'VIEW', 1, 'facilities')))
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('products', 'LIST')))
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('value-chains', 'LIST')))
        this.props.dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('emissions', 'LIST')))
    }

    submitFX = (formData, formId) => {
        // this.props.dispatch(callApi(apiResourceEndpoint('find-city', 'LIST', null, encodeURIComponent(formData.destination)), {}, formId))
    }

    successFX = () => {
        // console.log('got in here')
    }

    handleSelect = (place) => {
        const long = place.center[0]
        const lat = place.center[1]
        this.setState({
            place: place.place_name,
            long: place.center[0],
            lat: place.center[1]
        })
        this.props.dispatch(callApi(apiResourceEndpoint('find-nearest-port', 'LIST', null, `${lat}/${long}`), {}, -1))
    }

    formatOptions = (item) => {
        return (
            <div className="result">
                <span className="primary">{item.place_name}</span>
            </div>
        )
    }

    render = () => {
        return (
            <div>
                <h1>Transport Calculator</h1>
                <p>This will be a form to create many points along a route.</p>
                <Autocomplete
                    onSelect={this.handleSelect}
                    formatOptions={this.formatOptions}
                    disableAddOption={true}/>
                { this.state.place &&
                    <div>
                        Found {this.state.place}<br />
                        Longitude: {this.state.long}<br />
                        Latitude: {this.state.lat}
                    </div>
                }
            </div>
        )
    }
}

export default connect()(CreateRoute)
