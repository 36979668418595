import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'
import { routes } from '../constants/routes'
import DevTools from './utils/dev-tools'
import AppIndex from './app/index'
import AuthRouter from './auth/router'
import { isEmpty } from 'lodash'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { killState } from '../actions/auth'
import { removeAuth, saveAuth } from '../reducers/auth'
import './utils/icons'
import '../assets/scss/app.scss'
import Logout from './auth/logout'
import CreateUser from './auth/create-user'
import MarketingIndex from './marketing/index'
import PageHeader from './layout/page-header'
import ConfirmEmailSignin from './marketing/confirm-email-signin'

const ROUTER_PAGES = [routes.index, routes.authLogin, routes.router, routes.authRegister, routes.confirmEmailSignIn]

function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin} state={{ from: location }} />
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

const App = ({ dispatch, auth, user, location, firebaseApp, navigate }) => {
    const [hasAuthRouted, setHasAuthRouted] = useState(false)
    const [shouldRoute, setShouldRoute] = useState(false)
    const [fireauth, setFireauth] = useState(null)
    const pathname = location.pathname

    useEffect(() => {
        const fbAuth = getAuth(firebaseApp)
        setFireauth(fbAuth)
        const unsubscribe = onAuthStateChanged(fbAuth, (firebaseUser) => {
            if (firebaseUser) {
                dispatch(saveAuth(firebaseUser.toJSON()))
            } else {
                setHasAuthRouted(false)
                dispatch(killState())
                dispatch(removeAuth())
            }
        })
        return () => unsubscribe()
    }, [dispatch, firebaseApp])

    useEffect(() => {
        if (!hasAuthRouted && !isEmpty(auth)) {
            if (ROUTER_PAGES.indexOf(pathname) !== -1 || pathname.indexOf('/app/') !== -1) {
                setHasAuthRouted(true)
                setShouldRoute(true)
            }
        }
    }, [hasAuthRouted, auth, pathname])

    useEffect(() => {
        if (shouldRoute) {
            setShouldRoute(false)
            const fromState = location?.state?.from?.pathname ? location.state.from.pathname : null
            navigate(routes.router, { state: { from: { pathname: fromState } } })
        }
    }, [shouldRoute, location.state, navigate])

    return (
        <>
            <PageHeader />
            <Routes>
                <Route path={`${routes.appIndex}*`} element={<AppRoute auth={auth} location={location} fireauth={fireauth}><AppIndex /></AppRoute>} />
                <Route path={routes.router} element={<AppRoute auth={auth} location={location}><AuthRouter /></AppRoute>} />
                <Route path={routes.authUserCreate} element={<AppRoute auth={auth} location={location}><CreateUser /></AppRoute>} />
                <Route path={routes.authLogout} element={<Logout />} />
                <Route path={`${routes.index}*`} element={<MarketingIndex fireauth={fireauth} />} />
                <Route path={`${routes.index}*`} element={<ConfirmEmailSignin fireauth={fireauth} />} />
            </Routes>
            <DevTools />
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(App))
